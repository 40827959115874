import React from 'react'
import { observer } from 'mobx-react'
import formatDate from 'date-fns/format'
import isToday from 'date-fns/is_today'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import { selectedVisitsStore } from '../../store/selectedVisitsStore'
import { COLORS } from '../_styles/muiTheme'
import './Filter.css'

const styles = {
  icon: {
    fontSize: 20,
    color: COLORS.white70,
  },
  activeIcon: {
    fontSize: 20,
    color: 'white',
  },
}

const handleChange = e => selectedVisitsStore.setFilter(e.target.value)

const Filter = () => {
  const { day } = selectedVisitsStore.store
  return (
    <div className="filter f ai-c jc-sb">
      <h3 className="filter__title">
        <span>
          Visits {isToday(day) ? 'today' : `on ${formatDate(day, 'dddd')}`}
        </span>
        <span className="filter__title-date">{formatDate(day, 'D MMM')}</span>
      </h3>
      <Input
        value={selectedVisitsStore.filterText || ''}
        placeholder="enter truck number"
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon
              style={
                selectedVisitsStore.filterText &&
                selectedVisitsStore.filterText.length >= 3
                  ? styles.activeIcon
                  : styles.icon
              }
            />
          </InputAdornment>
        }
        style={styles.input}
        onChange={handleChange}
      />
    </div>
  )
}

export default observer(Filter)
