import { Auth } from 'aws-amplify'
import { observable } from 'mobx'

import { username } from '../env'
import * as log from '../log'
import '../api/aws'

export const authStore = observable.object(
  {
    initialized: false,
    authenticated: false,
    loading: false,
    error: null,

    async init() {
      try {
        this.authenticated = (await Auth.currentSession()) != null
      } catch (e) {
        log.dev('Auth.currentAuthenticatedUser', e)
      } finally {
        this.initialized = true
      }
    },

    get errorMessage() {
      return this.error && this.error.message
    },

    async signIn(password) {
      this.loading = true
      this.error = null
      try {
        await Auth.signIn(username, password)
        this.authenticated = true
      } catch (e) {
        log.error('Auth.signIn', e)
        this.error = e
      } finally {
        this.loading = false
      }
    },

    async signOut() {
      try {
        await Auth.signOut()
      } finally {
        this.setUnauthenticated()
      }
    },

    setUnauthenticated() {
      this.authenticated = false
    },

    resetError() {
      this.error = null
    },
  },
  {
    error: observable.ref,
  },
)

// noinspection JSIgnoredPromiseFromCall
authStore.init()
