import addHours from 'date-fns/add_hours'
import setHours from 'date-fns/set_hours'
import differenceInMilliseconds from 'date-fns/difference_in_milliseconds'
import { clock } from '@mindhive/time'

import * as log from '../log'

// Refresh the browser once every 24 hours
// Get any website updates at least once a day
// Get around limits imposed by AWS (like MQTT websocket can't be open more than 24 hours)

export const scheduleRefresh = () => {
  const now = clock()
  let refreshTime = setHours(now, 3) // Intentionally don't set minutes, etc. to zero so all clients don't refresh at once
  if (differenceInMilliseconds(refreshTime, now) <= 0) {
    refreshTime = addHours(refreshTime, 24)
  }
  log.dev(`Booted at: ${now}, refreshing at: ${refreshTime}`)
  setTimeout(() => {
    window.location.reload(true)
  }, differenceInMilliseconds(refreshTime, now))
}
