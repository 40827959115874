import { observable } from 'mobx'
import { matchScore } from 'shared/plateMatch'
import orderBy from 'lodash/orderBy'

import { visitsStore } from './visitsStore'

export const selectedVisitsStore = observable.object({
  store: visitsStore.today,
  filterText: null,
  sortType: ['enter', 'desc'],

  get visits() {
    if (!this.filterText || (this.filterText && this.filterText.length < 3)) {
      return this.store.allVisits
    }
    const filterText = this.filterText.toUpperCase()
    const eventFilter = e => matchScore(e.plate, filterText) != null
    return this.store.allVisits.filter(
      v => eventFilter(v.entry) || (v.exit && eventFilter(v.exit)),
    )
  },

  get sortedVisits() {
    const [type, order] = this.sortType
    switch (type) {
      case 'enter':
        return orderBy(this.visits, [v => v.entry.timestamp.getTime()], [order])
      case 'exit':
        return orderBy(
          this.visits,
          [
            v => (v.exit ? v.exit.timestamp.getTime() : Infinity),
            v => v.entry.timestamp.getTime(),
          ],
          [order, order],
        )
      case 'time':
        return orderBy(this.visits, [v => v.duration], [order])
      default:
        return this.visits
    }
  },

  setStore(store) {
    this.store = store
    this.filterText = null
  },

  setFilter(text) {
    this.filterText = text
  },

  setSortType(sortType) {
    this.sortType = sortType
  },
})
