import { API } from 'aws-amplify'
import {
  computed,
  decorate,
  observable,
  untracked,
  onBecomeUnobserved,
  onBecomeObserved,
} from 'mobx'
import gql from 'graphql-tag'
import datesEqual from 'date-fns/is_equal'
import isBefore from 'date-fns/is_before'
import differenceInSeconds from 'date-fns/difference_in_seconds'
import addDays from 'date-fns/add_days'
import orderBy from 'lodash/orderBy'
import ProgressiveBackoff from '@mindhive/time/ProgressiveBackoff'

import { minuteAccurateDuration, timeStore } from './timeStore'
import { getS3Url } from '../api/aws'
import {
  marshallTimestamp,
  unmarshallTimestamp,
  RANGED_VISIT_FIELDS,
  VISIT_FIELDS,
} from 'shared/dbFormats'
import { thumbnailPath } from 'shared/imageAttributes'
import * as log from '../log'

export const DAYS_TO_SHOW_IN_PAST = 3

const GET_DAY_VISITS_QUERY = gql`
  query GetDayVisits(
    $startTimestamp: AWSDateTime!
    $endTimestamp: AWSDateTime!
  ) {
    getDayVisits(startTimestamp: $startTimestamp, endTimestamp: $endTimestamp) {
      ...visitFields
    }
  }
  ${VISIT_FIELDS}
`

const UPDATED_SUBSCRIPTION = gql`
  subscription Updated {
    updated {
      ...rangedVisitsFields
    }
  }
  ${RANGED_VISIT_FIELDS}
`

const unmarshallEvent = ({
  timestamp,
  irImageKey,
  colorImageKey,
  ...rest
}) => ({
  ...rest,
  irImageUrl: getS3Url(irImageKey),
  colorImageUrl: getS3Url(colorImageKey),
  thumbnailUrl: thumbnailPath(getS3Url(colorImageKey)),
  timestamp: unmarshallTimestamp(timestamp),
})

const bestPlate = (entry, exit) => {
  if (!exit) {
    return entry
  }
  if (exit.length > entry.length) {
    return exit
  }
  return entry
}

class Visit {
  constructor({ entry, exit }) {
    this.id = entry.id
    this.complete = !!exit
    this.isMismatch = !!exit && entry.plate !== exit.plate
    this.onSite = exit == null
    this.displayPlate = bestPlate(entry.plate, exit && exit.plate)
    this.entry = unmarshallEvent(entry)
    this.exit = exit ? unmarshallEvent(exit) : null
  }

  get duration() {
    return differenceInSeconds(
      this.exit ? this.exit.timestamp : timeStore.now,
      this.entry.timestamp,
    )
  }

  get durationMinuteAccurate() {
    return minuteAccurateDuration(this.duration)
  }

  isOvernight(day) {
    return isBefore(this.entry.timestamp, day)
  }

  inRange({ startTimestamp, endTimestamp = null }) {
    return (
      (!endTimestamp || isBefore(this.entry.timestamp, endTimestamp)) &&
      (!this.exit || !isBefore(this.exit.timestamp, startTimestamp))
    )
  }

  entryInRange({ startTimestamp, endTimestamp }) {
    return (
      !isBefore(this.entry.timestamp, startTimestamp) &&
      isBefore(this.entry.timestamp, endTimestamp)
    )
  }
}
decorate(Visit, {
  duration: computed,
  durationMinuteAccurate: computed,
})

const unmarshallRangedVists = ({ visits, startTimestamp, endTimestamp }) => ({
  visits: visits.map(v => new Visit(v)),
  startTimestamp: unmarshallTimestamp(startTimestamp),
  endTimestamp: unmarshallTimestamp(endTimestamp),
})

class AbsoluteDayRange {
  constructor(startTimestamp) {
    this.startTimestamp = startTimestamp
    this.endTimestamp = addDays(startTimestamp, 1)
    this.key = `absolute-${startTimestamp.toString()}`
  }
}

class RelativeRange {
  constructor(daysOffset) {
    this.daysOffset = daysOffset
    this.key = `realative-${daysOffset}`
  }

  get startTimestamp() {
    return addDays(timeStore.today, this.daysOffset)
  }

  get endTimestamp() {
    return addDays(this.startTimestamp, 1)
  }
}
decorate(RelativeRange, {
  startTimestamp: computed({ equals: datesEqual }),
  endTimestamp: computed({ equals: datesEqual }),
})

class RecentRange {
  key = 'recent'

  get startTimestamp() {
    return addDays(timeStore.today, -DAYS_TO_SHOW_IN_PAST)
  }

  get endTimestamp() {
    return addDays(timeStore.today, 1)
  }
}
decorate(RecentRange, {
  startTimestamp: computed({ equals: datesEqual }),
  endTimestamp: computed({ equals: datesEqual }),
})

class QueryVisitsSource {
  range
  loading = true
  error = false
  rawVisits = []
  querySubscription = null

  constructor(range, { watchForUpdates = false } = {}) {
    this.range = range
    this.watchForUpdates = watchForUpdates
    let currentObserveInstance = 1
    onBecomeObserved(this.rawVisits, async () => {
      const thisInstance = currentObserveInstance
      const backoff = new ProgressiveBackoff()
      this.loading = true
      this.error = false
      do {
        try {
          if (thisInstance !== currentObserveInstance) {
            return
          }
          const loadResult = await this._load()
          if (thisInstance !== currentObserveInstance) {
            return
          }
          this.rawVisits.replace(loadResult)
          // Small chance of race condition? Updates happen between our query and this starting?
          // Or could subscribe before querying?
          if (this.watchForUpdates) {
            this.querySubscription = this._subscribe()
          }
          this.loading = false
          this.error = false
        } catch (e) {
          if (thisInstance !== currentObserveInstance) {
            return
          }
          log.error(e)
          this.error = true
          await backoff.sleep()
          log.dev('Retrying...')
        }
      } while (this.error)
    })
    onBecomeUnobserved(this.rawVisits, () => {
      currentObserveInstance += 1
      this.rawVisits.clear()
      if (this.querySubscription) {
        log.dev('Unsubscribing from query')
        this.querySubscription.unsubscribe()
      }
      this.querySubscription = null
    })
  }

  get visits() {
    const visitsInRange = this.rawVisits.filter(v => v.inRange(this.range))
    return orderBy(visitsInRange, [v => v.entry.timestamp.getTime()], ['desc'])
  }

  async _load() {
    const variables = untracked(() => ({
      // Untracked as we don't want to re-run load if range changes, subscriptions handle that
      startTimestamp: marshallTimestamp(this.range.startTimestamp),
      endTimestamp: marshallTimestamp(this.range.endTimestamp),
    }))
    log.dev('API.graphql')
    const result = await API.graphql({
      query: GET_DAY_VISITS_QUERY,
      variables,
    })
    if (result.errors) {
      throw new Error(result.errors.map(e => e.message).join('\n'))
    }
    log.dev('query result', result.data.getDayVisits.length)
    return result.data.getDayVisits.map(v => new Visit(v))
  }

  _subscribe() {
    log.dev('Subscribing to query')
    return API.graphql({
      query: UPDATED_SUBSCRIPTION,
    }).subscribe({
      next: nextResult => {
        log.dev('subscription update')
        this._updated(unmarshallRangedVists(nextResult.value.data.updated))
      },
      error: e => {
        log.error('subscription error', e)
        // TODO: Need to reload at this point??
        this.error = true
      },
    })
  }

  _updated(rangedVists) {
    log.dev(
      `Updating ${rangedVists.startTimestamp} -> ${rangedVists.endTimestamp ||
        'end'}`,
    )
    this.rawVisits.replace([
      ...this.rawVisits.filter(
        v => !v.inRange(rangedVists) && v.inRange(this.range), // Include check against our own range to cleanup
      ),
      ...rangedVists.visits,
    ])
  }
}
decorate(QueryVisitsSource, {
  error: observable,
  loading: observable,
  rawVisits: observable.shallow,
  visits: computed,
})

const recentSource = new QueryVisitsSource(new RecentRange(), {
  watchForUpdates: true,
})

class SubsetVisitsSource {
  range

  constructor(source, range) {
    this.source = source
    this.range = range
  }

  get error() {
    return this.source.error
  }

  get loading() {
    return this.source.loading
  }

  get visits() {
    return this.source.visits.filter(v => v.inRange(this.range))
  }
}
decorate(SubsetVisitsSource, {
  error: computed,
  loading: computed,
  visits: computed,
})

export const absoluteDaySource = day =>
  new QueryVisitsSource(new AbsoluteDayRange(day))

export const recentDaySource = dayOffset =>
  new SubsetVisitsSource(recentSource, new RelativeRange(dayOffset))
