import React, { Fragment } from 'react'
import { observer } from 'mobx-react'

import { authStore } from './authStore'
import SignInPage from './SignInPage/SignInPage'
import Loader from '../app/Loader/Loader'

const Authenticated = ({ children }) =>
  authStore.initialized ? (
    authStore.authenticated ? (
      <Fragment>{children}</Fragment>
    ) : (
      <SignInPage />
    )
  ) : (
    <Loader />
  )

export default observer(Authenticated)
