import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import formatDate from 'date-fns/format'

import { visitsStore } from '../../store/visitsStore'
import IMAGES from '../../utils/ImagesProvider'
import { formatDuration } from '../../utils/utils'
import './LiveList.css'

const Visit = observer(({ visit, index, overnight }) => {
  const fDate = formatString => formatDate(visit.entry.timestamp, formatString)
  return (
    <li key={visit.id} className="live-list__item f ai-c">
      <div className="live-list__item-plate f ai-c">
        <span className="live-list__item-index">{index}</span>{' '}
        {visit.displayPlate}
      </div>
      <div className="live-list__item-time">
        <span>
          {overnight && (
            <span className="visits-table__date-part">{fDate('DD MMM ')}</span>
          )}
          {fDate('h:mm ')}
          <span className="visits-table__date-part">{fDate('a')}</span>
        </span>
      </div>
      <div className="live-list__item-duration">
        {formatDuration(visit.durationMinuteAccurate)}
      </div>
    </li>
  )
})

const LiveList = () => {
  const {
    onSiteDayVisits,
    onSiteOvernightVisits,
    siteCount,
    loading,
  } = visitsStore.today
  if (loading) {
    return null
  }
  return (
    <div className="live-list">
      {onSiteDayVisits.length > 0 || onSiteOvernightVisits.length > 0 ? (
        <Fragment>
          <div className="live-list__caption f ai-c">
            <div className="live-list__caption-header">Plate</div>
            <div className="live-list__caption-header">Enter</div>
            <div className="live-list__caption-header">Time</div>
          </div>
          <ul>
            {onSiteDayVisits.map((visit, index) => (
              <Visit key={visit.id} visit={visit} index={siteCount - index} />
            ))}
          </ul>
          {onSiteOvernightVisits.length > 0 && (
            <Fragment>
              <div className="live-list__overnight f ai-c">
                <img
                  className="fs-0"
                  width={12}
                  height={12}
                  src={IMAGES.icon_moon}
                  alt="overnight"
                />
                <div className="live-list__overnight-text">overnight</div>
                <div className="live-list__overnight-thread" />
              </div>
              <ul>
                {onSiteOvernightVisits.map((visit, index) => (
                  <Visit
                    key={visit.id}
                    visit={visit}
                    index={onSiteOvernightVisits.length - index}
                    overnight
                  />
                ))}
              </ul>
            </Fragment>
          )}
        </Fragment>
      ) : (
        <div className="empty-data">No trucks</div>
      )}
    </div>
  )
}

export default observer(LiveList)
