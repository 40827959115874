import { observable, decorate, computed } from 'mobx'
import range from 'lodash/range'
import { median } from 'stats-lite'
import addHours from 'date-fns/add_hours'
import isBefore from 'date-fns/is_before'
import formatDate from 'date-fns/format'
import datesEqual from 'date-fns/is_equal'

import {
  absoluteDaySource,
  DAYS_TO_SHOW_IN_PAST,
  recentDaySource,
} from './visitsSource'
import { minuteAccurateDuration } from './timeStore'

class VisitsStore {
  source

  constructor(source) {
    this.source = source
    this.key = source.range.key
  }

  get loading() {
    return this.source.loading
  }

  get error() {
    return this.source.error
  }

  get day() {
    return this.source.range.startTimestamp
  }

  get allVisits() {
    return this.source.visits
  }

  get chartVisits() {
    const result = []
    let { startTimestamp } = this.source.range
    let hourRange
    const rangeFilter = v => v.entryInRange(hourRange)
    while (isBefore(startTimestamp, this.source.range.endTimestamp)) {
      hourRange = { startTimestamp, endTimestamp: addHours(startTimestamp, 1) }
      result.push({
        hour: formatDate(startTimestamp, 'ha').replace('m', ''),
        trucks: this.allVisits.filter(rangeFilter).length,
      })
      startTimestamp = addHours(startTimestamp, 1)
    }
    return result
  }

  get entryCount() {
    return this.allVisits.filter(v => !v.isOvernight(this.day)).length
  }

  get overnightCount() {
    return this.allVisits.filter(v => v.isOvernight(this.day)).length
  }

  get averageDurationMinuteAccurate() {
    return minuteAccurateDuration(median(this.allVisits.map(v => v.duration)))
  }

  get onSiteVisits() {
    return this.allVisits.filter(v => v.onSite)
  }

  get siteCount() {
    return this.onSiteVisits.length
  }

  get onSiteDayVisits() {
    return this.onSiteVisits.filter(v => !v.isOvernight(this.day))
  }

  get onSiteOvernightVisits() {
    return this.onSiteVisits.filter(v => v.isOvernight(this.day))
  }
}
decorate(VisitsStore, {
  loading: computed,
  error: computed,
  allVisits: computed,
  day: computed({ equals: datesEqual }),
  chartVisits: computed,
  entryCount: computed,
  overnightCount: computed,
  averageDurationMinuteAccurate: computed,
  onSiteVisits: computed,
  siteCount: computed,
  onSiteDayVisits: computed,
  onSiteOvernightVisits: computed,
})

export const visitsStore = observable.object({
  recent: range(-(DAYS_TO_SHOW_IN_PAST + 1)).map(
    i => new VisitsStore(recentDaySource(i)),
  ),

  get today() {
    return this.recent[0]
  },

  dateSelected: null,

  setSelectedDate(date) {
    this.dateSelected = date ? new VisitsStore(absoluteDaySource(date)) : null
  },
})
