import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'

import Authenticated from '../auth/Authenticated'
import { development } from '../env'
import MainPage from './MainPage/MainPage'
import muiTheme from './_styles/muiTheme'
import './_styles/base.css'

const MobxDevTools = development
  ? require('mobx-react-devtools').default // eslint-disable-line import/no-extraneous-dependencies
  : () => null

const theme = createMuiTheme(muiTheme)

const Root = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Authenticated>
      <MainPage />
    </Authenticated>
    <MobxDevTools position={{ right: 0, bottom: 0 }} />
  </MuiThemeProvider>
)

export default Root
