/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import cn from 'classnames'
import formatDate from 'date-fns/format'
import Img from 'react-image'
import Paper from '@material-ui/core/Paper'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import { COLORS } from '../_styles/muiTheme'
import './CellDetails.css'

const styles = {
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  infraredIcon: {
    fontSize: 30,
    marginRight: 10,
  },
  zoomIcon: {
    fontSize: 22,
  },
  zoomButton: {
    position: 'absolute',
    width: 40,
    minWidth: 40,
    height: 34,
    minHeight: 34,
    padding: 0,
    right: 10,
    bottom: 10,
    background: COLORS.black60,
    color: 'white',
  },
  zoomWhiteIcon: {
    fontSize: 25,
  },
  iconButton: {
    width: 36,
    height: 36,
    color: COLORS.lightBlue,
    marginLeft: 5,
  },
  buttonBase: {
    marginRight: 35,
    borderRadius: 2,
    overflow: 'hidden',
    width: 275,
    height: 200,
  },
}

const renderImgLoader = () => (
  <div className="cell-details__image f ai-c jc-c">
    <CircularProgress color="secondary" size={50} />
  </div>
)

const Plate = ({ event, label, onClick, isMatch }) => (
  <div className="cell-details__plate">
    {/* eslint-disable-next-line */}
    <a className="cell-details__link f-i ai-c" onClick={onClick}>
      <div
        className={cn('cell-details__number', {
          'cell-details__number_duplicate': isMatch,
        })}
      >
        {event.plate}
      </div>
      <IconButton style={styles.iconButton}>
        <ZoomInIcon style={styles.zoomIcon} />
      </IconButton>
    </a>
    <div className="cell-details__subtext">{label}</div>
  </div>
)

const CellDetails = ({ day, visit, onRowClose, onModalSet }) => (
  <Paper elevation={5} className="cell-details" style={styles.paper}>
    <div className="cell-details__container container container_small f fd-c jc-sb">
      <div className="cell-details__time">
        Photo at{' '}
        {visit.isOvernight(day)
          ? formatDate(visit.entry.timestamp, 'DD MMM ')
          : ''}
        {formatDate(visit.entry.timestamp, 'h:mm a')}
      </div>
      <div className="f">
        <ButtonBase
          component="a"
          className="cell-details__image-block f ai-c fs-0"
          style={styles.buttonBase}
          onClick={onModalSet('entry')}
        >
          <Img
            className="cell-details__image"
            src={visit.entry.thumbnailUrl}
            loader={renderImgLoader()}
            alt={visit.entry.plate}
          />
          {visit.exit && (
            <img
              className="cell-details__image cell-details__image_hidden"
              src={visit.exit.thumbnailUrl}
              alt={visit.exit.plate}
            />
          )}
          <Button className="cell-details__zoom" style={styles.zoomButton}>
            <ZoomInIcon style={styles.zoomWhiteIcon} />
          </Button>
        </ButtonBase>
        <div className="cell-details__info f fd-c jc-sb">
          <div>
            <div className="cell-details__block f ai-c">
              <ImageOutlinedIcon style={styles.infraredIcon} />
              <div className="cell-details__number-title">
                Gate
                <br />
                photos
              </div>
            </div>
            <Plate
              event={visit.entry}
              label="entry"
              onClick={onModalSet('entry')}
            />
            {visit.exit && (
              <Plate
                event={visit.exit}
                label="exit"
                onClick={onModalSet('exit')}
                isMatch={visit.entry.plate === visit.exit.plate}
              />
            )}
          </div>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={onRowClose}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  </Paper>
)

export default CellDetails
