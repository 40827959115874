import React, { Component } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import {
  DayPickerSingleDateController,
  isInclusivelyBeforeDay,
} from 'react-dates'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'

import { visitsStore } from '../store/visitsStore'

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
})

const styles = {
  paper: {
    position: 'relative',
  },
  clearIcon: {
    fontSize: 14,
  },
  iconButton: {
    position: 'absolute',
    zIndex: 1,
    right: 2,
    top: 6,
    width: 26,
    height: 26,
  },
}

class SelectedDate extends Component {
  handleDateChange = date => {
    visitsStore.setSelectedDate(
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    )
    this.props.onDateSelectedToggle()
  }

  render() {
    const { onResetSelect } = this.props
    return (
      <Paper style={styles.paper}>
        <IconButton style={styles.iconButton} onClick={onResetSelect}>
          <ClearIcon style={styles.clearIcon} />
        </IconButton>
        <DayPickerSingleDateController
          enableOutsideDays
          date={visitsStore.dateSelected && moment(visitsStore.day)}
          daySize={26}
          isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
          onDateChange={this.handleDateChange}
        />
      </Paper>
    )
  }
}

export default observer(SelectedDate)
