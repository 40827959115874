import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import formatDate from 'date-fns/format'
import isToday from 'date-fns/is_today'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import Paper from '@material-ui/core/Paper'

import { timeStore } from '../../store/timeStore'
import IMAGES from '../../utils/ImagesProvider'
import Doughnut from '../Doughnut/Doughnut'
import Loader from '../Loader/Loader'
import { COLORS } from '../_styles/muiTheme'
import './DayCardDetails.css'

const pxValue = 176.6

const styles = {
  paper: {
    marginBottom: 12,
  },
  chartMargin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  chartTooltip: {
    color: COLORS.slateGrey,
  },
  tick: {
    fill: COLORS.warmGrey,
    fontSize: 10,
    fontWeight: 600,
  },
  cursor: {
    opacity: 0.05,
  },
}

const renderCursor = ({ active, payload }) => {
  if (!active || !payload[0].value) return null
  return <div className="label">{payload[0].value}</div>
}

const renderStats = stats =>
  stats.map(({ type, icon, value, text }) => (
    <li key={type} className="card-details__stats-item">
      <div className="f ai-c">
        <div className="stats-item__count">{value}</div>
        <img width={21} height={21} src={icon} alt={type} />
      </div>
      <div className="stats-item__legend">{text}</div>
    </li>
  ))

const DayCardDetails = ({ store }) => {
  const {
    entryCount,
    chartVisits,
    overnightCount,
    averageDurationMinuteAccurate: averageDuration,
    day,
    loading,
  } = store
  const stats = [
    {
      type: 'entry',
      icon: IMAGES.icon_truck_checked,
      value: entryCount,
      text: 'total entries',
    },
    ...(overnightCount
      ? [
          {
            type: 'overnight',
            icon: IMAGES.icon_moon,
            value: overnightCount,
            text: 'overnight',
          },
        ]
      : []),
  ]
  const pxTimeStart = isToday(day)
    ? (timeStore.nowMinuteAccurate - day) / 1000 / pxValue + 47
    : 0
  const timeStyle = {
    left: pxTimeStart,
  }
  return (
    <Paper style={styles.paper}>
      <div className="card-details container">
        {loading && <Loader block />}
        <Fragment>
          <div className="card-details__header f ai-fe jc-sb">
            <div>
              <h2 className="card-details__title">
                {isToday(day) ? 'Today' : formatDate(day, 'D MMMM')}
              </h2>
              <div className="card-details__subtitle">
                Truck entries per hour
              </div>
            </div>
            {!loading && (
              <div className="card-details__stats f ai-c jc-sb">
                <ul className="card-details__stats-list f jc-sb">
                  {renderStats(stats)}
                </ul>
                <div className="card-details__stats-last-item f ai-c">
                  <Doughnut title="average time" value={averageDuration} />
                </div>
              </div>
            )}
          </div>
          {!loading && (
            <div className="card-details__stats-graph">
              <BarChart
                width={550}
                height={180}
                data={chartVisits}
                margin={styles.chartMargin}
              >
                <CartesianGrid
                  vertical={false}
                  strokeDasharray="4"
                  opacity={0.2}
                />
                <XAxis
                  dataKey="hour"
                  tickLine={false}
                  axisLine={false}
                  minTickGap={10}
                  tick={styles.tick}
                />
                <YAxis
                  allowDecimals={false}
                  tickLine={false}
                  axisLine={false}
                  tick={styles.tick}
                />
                <Tooltip cursor={styles.cursor} content={renderCursor} />\{' '}
                <Bar barSize={4} dataKey="trucks" fill={COLORS.lightBlue} />
              </BarChart>
            </div>
          )}
        </Fragment>
        {!loading && Boolean(pxTimeStart) && (
          <div className="card-details__time" style={timeStyle}>
            <div className="f ai-c">
              <div className="card-details__time-point" />
              <div>{formatDate(timeStore.nowMinuteAccurate, 'h:mm a')}</div>
            </div>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default observer(DayCardDetails)
