import Amplify from 'aws-amplify'

import {
  graphQlApiEndpoint,
  awsRegion,
  identityPoolId,
  userPoolId,
  userPoolClientId,
  imageBucket,
} from '../env'

Amplify.configure({
  Auth: {
    identityPoolId,
    region: awsRegion,
    userPoolId,
    userPoolWebClientId: userPoolClientId,
    mandatorySignIn: true,
  },
  aws_appsync_graphqlEndpoint: graphQlApiEndpoint,
  aws_appsync_region: awsRegion,
})

export const getS3Url = key =>
  `https://s3-${awsRegion}.amazonaws.com/${imageBucket}/${key}`
