import React, { Component } from 'react'
import { observer } from 'mobx-react'
import ButtonBase from '@material-ui/core/ButtonBase'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import DateRangeIcon from '@material-ui/icons/DateRange'

import './Tabs.css'
import { visitsStore } from '../../store/visitsStore'
import { selectedVisitsStore } from '../../store/selectedVisitsStore'
import DayCard from '../DayCard/DayCard'
import SelectedDate from '../SelectedDate'

const styles = {
  buttonBase: {
    height: 105,
    marginBottom: 10,
    borderRadius: 2,
  },
  paper: {
    borderRadius: 2,
    padding: '20px 22px 20px 18px',
    height: '100%',
  },
  calendarIcon: {
    fontSize: 16,
    marginRight: 5,
  },
}

const initialState = {
  startSelect: false,
  dateSelected: false,
}

class Tabs extends Component {
  state = initialState

  handleStartSelectOn = () => this.setState({ startSelect: true })

  handleDateSelectedToggle = () =>
    this.setState(prevState => ({
      dateSelected: !prevState.dateSelected,
    }))

  handleResetSelect = () => {
    if (visitsStore.dateSelected) {
      selectedVisitsStore.setStore(visitsStore.today)
    }
    this.setState(initialState)
    visitsStore.setSelectedDate(null)
  }

  renderExtraCard = () => {
    const { startSelect, dateSelected } = this.state
    if (!startSelect) {
      return (
        <ButtonBase
          focusRipple
          component="a"
          className="tabs__card"
          style={styles.buttonBase}
          onClick={this.handleStartSelectOn}
        >
          <Paper style={styles.paper}>
            <div className="tabs__select">
              <AddIcon />
              <div className="tabs__select-day f ai-c jc-c">
                <DateRangeIcon style={styles.calendarIcon} />
                Select day
              </div>
            </div>
          </Paper>
        </ButtonBase>
      )
    }
    return dateSelected && visitsStore.dateSelected ? (
      <DayCard
        store={visitsStore.dateSelected}
        styleProps={styles}
        onDateSelectedToggle={this.handleDateSelectedToggle}
      />
    ) : (
      <SelectedDate
        onResetSelect={this.handleResetSelect}
        onDateSelectedToggle={this.handleDateSelectedToggle}
      />
    )
  }

  render() {
    return (
      <div className="tabs">
        {visitsStore.recent.map(s => (
          <DayCard key={s.key} store={s} styleProps={styles} />
        ))}
        {this.renderExtraCard()}
      </div>
    )
  }
}

export default observer(Tabs)
