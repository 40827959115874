import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import formatDate from 'date-fns/format'
import addDays from 'date-fns/add_days'
import isToday from 'date-fns/is_today'
import Img from 'react-image'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import LeakAddIcon from '@material-ui/icons/LeakAdd'
import ButtonBase from '@material-ui/core/ButtonBase'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import { timeStore } from '../../store/timeStore'

import Doughnut from '../Doughnut/Doughnut'
import './Modal.css'

const hours = ['3 am', '6 am', '9 am', '12 pm', '3 pm', '6 pm', '9 pm', '12 am']
const pxValue = 185

const styles = {
  buttonBase: {
    width: 184,
    height: 62,
    marginBottom: 10,
    borderRadius: 2,
    padding: '0 30px 0 24px',
    display: 'flex',
  },
  icon: {
    marginRight: 8,
    fontSize: 28,
  },
  arrowButton: {
    width: 60,
    height: 60,
    position: 'absolute',
    left: -70,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  arrowRightButton: {
    left: 'auto',
    right: -70,
  },
  arrowIcon: {
    width: 48,
    height: 48,
  },
}

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image: `${props.modalType} color`,
    }
  }

  handleImageSet = image => () => this.setState({ image })

  handlePrevVisitSelect = () => {
    const { prevVisit, onRowSelect } = this.props
    this.setState({ image: 'entry color' })
    onRowSelect(prevVisit.id)()
  }

  handleNextVisitSelect = () => {
    const { nextVisit, onRowSelect } = this.props
    this.setState({ image: 'entry color' })
    onRowSelect(nextVisit.id)()
  }

  renderBarBlock = type => {
    const { day, visit } = this.props
    const { image } = this.state
    return (
      <div className="modal__block">
        <div className="modal__subtitle">
          {visit.isMismatch && (
            <span className="modal__number-mismatch">{visit[type].plate} </span>
          )}
          {type}{' '}
          {Boolean(type === 'entry' && visit.isOvernight(day)) &&
            formatDate(visit.entry.timestamp, 'DD MMM ')}
          {Boolean(type === 'exit' && visit.exit.timestamp > addDays(day, 1)) &&
            formatDate(visit.exit.timestamp, 'DD MMM ')}
          {formatDate(visit[type].timestamp, 'h:mm a')}
        </div>
        <ButtonBase
          focusRipple
          component="a"
          className={cn('modal__card', {
            modal__card_selected: image === `${type} color`,
          })}
          style={styles.buttonBase}
          onClick={this.handleImageSet(`${type} color`)}
        >
          <div className="f ai-c">
            <ImageOutlinedIcon style={styles.icon} />
            <div className="modal__card-text">Coloured photo</div>
          </div>
        </ButtonBase>
        <ButtonBase
          focusRipple
          component="a"
          className={cn('modal__card', {
            modal__card_selected: image === `${type} ir`,
          })}
          style={styles.buttonBase}
          onClick={this.handleImageSet(`${type} ir`)}
        >
          <div className="f ai-c">
            <LeakAddIcon style={styles.icon} />
            <div className="modal__card-text">Infrared scan number</div>
          </div>
        </ButtonBase>
      </div>
    )
  }

  renderImgLoader = thumbnail => (
    <div
      className="modal__image-loader f ai-c jc-c"
      style={thumbnail ? { backgroundImage: `url(${thumbnail})` } : null}
    >
      <CircularProgress color="secondary" size={50} />
    </div>
  )

  render() {
    const { day, visit, onModalCLose } = this.props
    const { image } = this.state
    const imageParts = image.split(' ')
    const title =
      imageParts[0] === 'entry' ? visit.entry.plate : visit.exit.plate
    const pxTimeStart =
      visit && isToday(day)
        ? (timeStore.nowMinuteAccurate - day) / 1000 / pxValue - 4.5
        : 0
    const pxRangeStart = visit
      ? (visit.entry.timestamp - day) / 1000 / pxValue
      : 0
    const pxRangeLength = visit ? visit.durationMinuteAccurate / pxValue : 0
    const rangeStyle = {
      left: pxRangeStart,
      width: pxRangeLength,
    }
    const timeStyle = {
      left: pxTimeStart,
    }
    return (
      <div className="modal">
        <IconButton
          style={styles.arrowButton}
          onClick={this.handlePrevVisitSelect}
        >
          <KeyboardArrowLeftIcon style={styles.arrowIcon} />
        </IconButton>
        <IconButton
          style={{ ...styles.arrowButton, ...styles.arrowRightButton }}
          onClick={this.handleNextVisitSelect}
        >
          <KeyboardArrowRightIcon style={styles.arrowIcon} />
        </IconButton>
        {visit && (
          <Fragment>
            <div className="modal__header f jc-sb">
              <div>
                <h2 className="modal__title">{title}</h2>
                <div className="modal__subtitle">
                  Truck {isToday(day) ? 'Today' : formatDate(day, 'D MMMM')}
                </div>
              </div>
              <div className="modal__graphs f ai-c jc-sb">
                {isToday(day) && (
                  <div className="modal__line-time f ai-c" style={timeStyle}>
                    <div className="modal__line-disc" />
                    <div>
                      {formatDate(timeStore.nowMinuteAccurate, 'h:mm a')}
                    </div>
                  </div>
                )}
                <div className="modal__line">
                  <div className="modal__line-thread">
                    <div className="modal__line-range" style={rangeStyle} />
                  </div>
                  <ul className="f jc-sb">
                    {hours.map(hour => (
                      <li key={hour} className="modal__line-hour">
                        {hour}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="modal__pie f ai-c fs-0">
                  <Doughnut
                    title={
                      <span>
                        total
                        <br />
                        time
                      </span>
                    }
                    value={visit.durationMinuteAccurate}
                  />
                </div>
              </div>
            </div>
            <div className="modal__content f jc-sb">
              <div className="f fd-c jc-sb">
                <div>
                  {this.renderBarBlock('entry')}
                  {visit.exit && this.renderBarBlock('exit')}
                </div>
                <Button
                  fullWidth
                  size="small"
                  variant="outlined"
                  onClick={onModalCLose}
                >
                  Close
                </Button>
              </div>
              <Img
                className="modal__image"
                src={visit[imageParts[0]][`${imageParts[1]}ImageUrl`]}
                loader={this.renderImgLoader(
                  imageParts[1] === 'color'
                    ? visit[imageParts[0]].thumbnailUrl
                    : '',
                )}
                alt={image}
              />
            </div>
          </Fragment>
        )}
      </div>
    )
  }
}

export default observer(Modal)
