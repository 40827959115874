import React from 'react'
import { observer } from 'mobx-react'
import Paper from '@material-ui/core/Paper'

import LiveHeader from '../LiveHeader/LiveHeader'
import LiveList from '../LiveList/LiveList'
import './LiveStats.css'

const LiveStats = () => (
  <Paper>
    <div className="live-stats container">
      <LiveHeader />
      <LiveList />
    </div>
  </Paper>
)

export default observer(LiveStats)
