import React from 'react'
import { observer } from 'mobx-react'
import Paper from '@material-ui/core/Paper'

import { selectedVisitsStore } from '../../store/selectedVisitsStore'
import DayCardDetails from '../DayCardDetails/DayCardDetails'
import Filter from '../Filter/Filter'
import VisitsTable from '../VisitsTable/VisitsTable'
import './Stats.css'

const Stats = () => (
  <div>
    <DayCardDetails store={selectedVisitsStore.store} />
    <Paper>
      <div className="stats-container container">
        <Filter />
        <VisitsTable />
      </div>
    </Paper>
  </div>
)

export default observer(Stats)
