import React, { Component } from 'react'
import { observer } from 'mobx-react'
import formatDate from 'date-fns/format'
import cn from 'classnames'
import { BarChart, Bar } from 'recharts'
import CircularProgress from '@material-ui/core/CircularProgress'
import ButtonBase from '@material-ui/core/ButtonBase'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'

import { selectedVisitsStore } from '../../store/selectedVisitsStore'
import IMAGES from '../../utils/ImagesProvider'
import { formatNoDaysDuration } from '../../utils/utils'
import './DayCard.css'

const styles = {
  clearIcon: {
    fontSize: 14,
  },
  iconButton: {
    position: 'absolute',
    zIndex: 1,
    right: 6,
    top: 2,
    width: 26,
    height: 26,
  },
}

class DayCard extends Component {
  componentDidMount() {
    const { onDateSelectedToggle } = this.props
    if (onDateSelectedToggle) {
      this.handleSourceSet()
    }
  }

  handleSourceSet = () => selectedVisitsStore.setStore(this.props.store)

  render() {
    const { store, styleProps, onDateSelectedToggle = null } = this.props
    const {
      chartVisits,
      entryCount,
      overnightCount,
      averageDurationMinuteAccurate: averageDuration,
      day,
      error,
      loading,
    } = store
    const dateElems = formatDate(day, 'ddd D MMM').split(' ')
    const stats = [
      { type: 'entry', icon: IMAGES.icon_truck_checked, value: entryCount },
      ...(overnightCount
        ? [{ type: 'overnight', icon: IMAGES.icon_moon, value: overnightCount }]
        : []),
      {
        type: 'timer',
        icon: IMAGES.icon_timer,
        value: formatNoDaysDuration(averageDuration),
      },
    ]
    return (
      <div className="card-wrapper">
        {onDateSelectedToggle && (
          <IconButton style={styles.iconButton} onClick={onDateSelectedToggle}>
            <ClearIcon style={styles.clearIcon} />
          </IconButton>
        )}
        <ButtonBase
          focusRipple
          component="a"
          className={cn('card', {
            card_selected: selectedVisitsStore.store === store,
          })}
          style={styleProps.buttonBase}
          onClick={this.handleSourceSet}
        >
          <Paper style={styleProps.paper}>
            <div className="card__content f jc-sb">
              <div className="card__date f fd-c jc-sb fs-0">
                <div className="card__date-text">{dateElems[0]}</div>
                <div>{dateElems[1]}</div>
                <div className="card__date-text card__date-text_month">
                  {dateElems[2]}
                </div>
              </div>
              {loading ? (
                <div className="card__stats f ai-c jc-c">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <div className="card__stats f fd-c jc-fe">
                  <div className="card__graph">
                    <BarChart
                      width={118}
                      height={35}
                      data={chartVisits}
                      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    >
                      <Bar barSize={2} dataKey="trucks" fill="white" />
                    </BarChart>
                  </div>
                  <ul className="f jc-sb">
                    {stats.map(({ type, icon, value }) => (
                      <li key={type} className="card__stats-item f ai-c">
                        <img
                          className="card__stats-icon"
                          src={icon}
                          alt={type}
                        />
                        {value}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {!loading && error && <div>Error</div>}
            <div
              className={cn('card__border', {
                card__border_active: selectedVisitsStore.store === store,
              })}
            />
          </Paper>
        </ButtonBase>
      </div>
    )
  }
}

export default observer(DayCard)
