import React, { Component } from 'react'
import { observer } from 'mobx-react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { authStore } from '../authStore'
import Loader from '../../app/Loader/Loader'
import './SignInForm.css'

const styles = {
  button: {
    marginTop: 24,
  },
}

class SignInForm extends Component {
  state = {
    password: '',
  }

  handleSubmit = e => {
    e.preventDefault()
    authStore.signIn(this.state.password)
  }

  handleChange = ({ target: { value } }) => {
    if (authStore.errorMessage) authStore.resetError()
    this.setState({ password: value })
  }

  render() {
    const { password } = this.state
    return (
      <form className="sign-in__form" onSubmit={this.handleSubmit}>
        {authStore.loading && <Loader />}
        <TextField
          autoFocus
          fullWidth
          value={password}
          id="password"
          label="Password"
          type="password"
          margin="normal"
          error={Boolean(authStore.errorMessage)}
          onChange={this.handleChange}
        />
        {authStore.errorMessage && (
          <div className="error">{authStore.errorMessage}</div>
        )}
        <Button
          fullWidth
          type="submit"
          variant="raised"
          color="primary"
          style={styles.button}
          disabled={!password}
        >
          Sign in
        </Button>
      </form>
    )
  }
}

export default observer(SignInForm)
