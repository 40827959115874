import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react'
import formatDate from 'date-fns/format'
import isSameDat from 'date-fns/is_same_day'
import cn from 'classnames'
import { CSSTransition } from 'react-transition-group'
import ButtonBase from '@material-ui/core/ButtonBase'
import FilterListIcon from '@material-ui/icons/FilterList'
import Dialog from '@material-ui/core/Dialog'

import { selectedVisitsStore } from '../../store/selectedVisitsStore'
import Loader from '../Loader/Loader'
import CellDetails from '../CellDetails/CellDetails'
import Modal from '../Modal/Modal'
import { formatDuration } from '../../utils/utils'
import './VisitsTable.css'

const styles = {
  buttonBaseHeaderCell: {
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonBaseRow: {
    display: 'flex',
  },
  icon: {
    fontSize: 16,
    marginLeft: 5,
    transition: 'transform 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
}

const DateCell = ({ date }) => {
  const fDate = formatString => formatDate(date, formatString)
  return (
    <span>
      {!isSameDat(date, selectedVisitsStore.store.day) && (
        <span className="visits-table__date-part">{fDate('DD MMM ')}</span>
      )}
      {fDate('h:mm ')}
      <span className="visits-table__date-part">{fDate('a')}</span>
    </span>
  )
}

const Visit = observer(
  ({ visit, selected, index, handleRowSelect, handleModalSet }) => {
    const { day } = selectedVisitsStore.store
    const [type] = selectedVisitsStore.sortType
    return (
      <div key={visit.id} className="visits-table__body-row-block">
        <ButtonBase
          focusRipple
          component="div"
          className={cn(
            'visits-table__row',
            { 'visits-table__row_selected': selected },
            'visits-table__group',
          )}
          style={styles.buttonBaseRow}
          onClick={handleRowSelect(visit.id)}
        >
          <div className="visits-table__cell f ai-c jc-c">
            <div className="visits-table__number">{index}</div>
          </div>
          <div className="visits-table__cell f ai-c">
            <div
              className={cn('visits-table__plate-point', {
                'visits-table__plate-point_site': visit.onSite,
              })}
            />
            <div className="visits-table__plate">{visit.displayPlate}</div>
          </div>
          <div
            className={cn(
              'visits-table__cell',
              { 'visits-table__cell_sort': type === 'enter' },
              'f ai-c jc-fe',
            )}
          >
            <DateCell date={visit.entry.timestamp} />
          </div>
          <div
            className={cn(
              'visits-table__cell',
              { 'visits-table__cell_sort': type === 'exit' },
              'f ai-c jc-fe',
            )}
          >
            {visit.onSite ? (
              <div className="label f ai-c jc-c">on yard</div>
            ) : (
              <DateCell date={visit.exit.timestamp} />
            )}
          </div>
          <div
            className={cn(
              'visits-table__cell',
              { 'visits-table__cell_sort': type === 'time' },
              'f ai-c jc-fe',
            )}
          >
            <div
              className={
                visit.onSite ? 'visits-table__site-date' : 'visits-table__date'
              }
            >
              {formatDuration(visit.durationMinuteAccurate)}
            </div>
          </div>
        </ButtonBase>
        <CSSTransition
          unmountOnExit
          in={selected}
          timeout={500}
          classNames="visits-table__cell-details"
        >
          <CellDetails
            day={day}
            visit={visit}
            onRowClose={handleRowSelect(null)}
            onModalSet={handleModalSet}
          />
        </CSSTransition>
      </div>
    )
  },
)

class VisitsTable extends Component {
  state = {
    modalType: null,
    selectedVisitId: null,
  }

  handleRowSelect = id => () =>
    this.setState(({ selectedVisitId }) => ({
      selectedVisitId: selectedVisitId === id ? null : id,
    }))

  handleModalSet = modalType => () => this.setState({ modalType })

  handleModalCLose = () => this.setState({ modalType: null })

  handleSortTypeSet = type => () => {
    const [currentType, currentOrder] = selectedVisitsStore.sortType
    if (currentType === type && currentOrder === 'desc') {
      selectedVisitsStore.setSortType([type, 'asc'])
    } else {
      selectedVisitsStore.setSortType([type, 'desc'])
    }
  }

  renderSortHeader = title => {
    const [type, order] = selectedVisitsStore.sortType
    return (
      <div className="f ai-c">
        {title}
        <FilterListIcon
          className={cn('visits-table__filter', {
            'visits-table__filter_asc': title === type && order === 'asc',
          })}
          style={styles.icon}
        />
      </div>
    )
  }

  render() {
    if (selectedVisitsStore.store.loading) {
      return <Loader block />
    }
    const { sortedVisits: visits, sortType } = selectedVisitsStore
    const { modalType, selectedVisitId } = this.state
    const selectedIndex = visits.findIndex(
      visit => visit.id === selectedVisitId,
    )
    const prevVisit =
      selectedIndex > 0 ? visits[selectedIndex - 1] : visits[visits.length - 1]
    const nextVisit =
      selectedIndex < visits.length - 1 ? visits[selectedIndex + 1] : visits[0]
    const [type, order] = sortType
    return (
      <Fragment>
        <div className="visits-table">
          <div className="visits-table__header visits-table__group f">
            <div className="visits-table__header-cell f ai-c jc-c">#</div>
            <div className="visits-table__header-cell f ai-c">Plate</div>
            <ButtonBase
              focusRipple
              component="div"
              className={cn(
                'visits-table__header-cell visits-table__header-cell_sort',
                {
                  [`visits-table__header-cell_sort_${order}`]:
                    type === 'enter' && order,
                },
              )}
              style={styles.buttonBaseHeaderCell}
              onClick={this.handleSortTypeSet('enter')}
            >
              {this.renderSortHeader('enter')}
            </ButtonBase>
            <ButtonBase
              focusRipple
              component="div"
              className={cn(
                'visits-table__header-cell visits-table__header-cell_sort',
                {
                  [`visits-table__header-cell_sort_${order}`]:
                    type === 'exit' && order,
                },
              )}
              style={styles.buttonBaseHeaderCell}
              onClick={this.handleSortTypeSet('exit')}
            >
              {this.renderSortHeader('exit')}
            </ButtonBase>
            <ButtonBase
              focusRipple
              component="div"
              className={cn(
                'visits-table__header-cell visits-table__header-cell_sort',
                {
                  [`visits-table__header-cell_sort_${order}`]:
                    type === 'time' && order,
                },
              )}
              style={styles.buttonBaseHeaderCell}
              onClick={this.handleSortTypeSet('time')}
            >
              {this.renderSortHeader('time')}
            </ButtonBase>
          </div>
          <div className="visits-table__body">
            {visits.map((visit, index) => (
              <Visit
                key={visit.id}
                visit={visit}
                selected={visit.id === selectedVisitId}
                index={visits.length - index}
                handleRowSelect={this.handleRowSelect}
                handleModalSet={this.handleModalSet}
              />
            ))}
          </div>
        </div>
        {!visits.length && <div className="empty-data">No matches</div>}
        <Dialog
          open={Boolean(modalType)}
          onClose={this.handleModalCLose}
          scroll="body"
        >
          <Modal
            day={selectedVisitsStore.store.day}
            visit={visits.find(visit => visit.id === selectedVisitId)}
            prevVisit={prevVisit}
            nextVisit={nextVisit}
            modalType={modalType}
            onRowSelect={this.handleRowSelect}
            onModalCLose={this.handleModalCLose}
          />
        </Dialog>
      </Fragment>
    )
  }
}

export default observer(VisitsTable)
