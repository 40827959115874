import React from 'react'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'

import IMAGES from '../../utils/ImagesProvider'
import SignInForm from '../SignInForm/SignInForm'
import { version } from '../../env'
import './SignInPage.css'

const styles = {
  paper: {
    padding: 40,
    marginTop: 64,
    marginBottom: 64,
  },
  avatar: {
    width: 60,
    height: 60,
    padding: 15,
  },
}

const SignInPage = () => (
  <div className="sign-in">
    <Paper style={styles.paper}>
      <div className="f fd-c ai-c">
        <Avatar alt="Logo" src={IMAGES.icon_logo} style={styles.avatar} />
        <h1 className="sign-in__title">Sign in</h1>
        <SignInForm />
      </div>
    </Paper>
    <div className="version">version: {version}</div>
  </div>
)

export default SignInPage
