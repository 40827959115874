import React, { Fragment } from 'react'
import { PieChart, Pie, Cell } from 'recharts'

import { formatNoDaysDuration } from '../../utils/utils'
import { COLORS } from '../_styles/muiTheme'
import './Doughnut.css'

const styles = {
  pieChart: {
    transform: 'rotate(-90deg)',
  },
}
const PIE_COLORS = [COLORS.white20, COLORS.pear]
const SECONDS_IN_HOUR = 60 * 60

const Doughnut = ({ title, value }) => {
  const pieData = [
    { name: 'day', value: SECONDS_IN_HOUR - value },
    { value, name: 'duration' },
  ]
  return (
    <Fragment>
      <div className="doughnut">
        <div className="doughnut__count">{formatNoDaysDuration(value)}</div>
        <PieChart width={44} height={44} style={styles.pieChart}>
          <Pie
            data={pieData}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={18}
            outerRadius={22}
            animationBegin={0}
            animationDuration={500}
          >
            {pieData.map((entry, index) => (
              <Cell key={entry.name} fill={PIE_COLORS[index]} strokeWidth={0} />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className="doughnut-legend">{title}</div>
    </Fragment>
  )
}

export default Doughnut
