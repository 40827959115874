export const version = process.env.REACT_APP_VERSION
export const development =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_STAGE === 'dev'
export const awsRegion = process.env.REACT_APP_AWS_REGION
export const imageBucket = process.env.REACT_APP_IMAGE_BUCKET
export const userPoolId = process.env.REACT_APP_USER_POOL_ID
export const userPoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID
export const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID
export const username = process.env.REACT_APP_USERNAME
export const graphQlApiEndpoint = process.env.REACT_APP_GRAPHQL_API_ENDPOINT
