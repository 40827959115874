import { observable, computed } from 'mobx'
import { clock } from '@mindhive/time'
import startOfDay from 'date-fns/start_of_day'
import startOfMinute from 'date-fns/start_of_minute'
import datesEqual from 'date-fns/is_equal'

const UPDATE_RATE_MS = 10000

export const minuteAccurateDuration = duration => Math.round(duration / 60) * 60

export const timeStore = observable.object(
  {
    now: clock(),

    get nowMinuteAccurate() {
      return startOfMinute(this.now)
    },

    get today() {
      return startOfDay(this.now)
    },
  },
  {
    now: observable.ref,
    nowMinuteAccurate: computed({ equals: datesEqual }),
    today: computed({ equals: datesEqual }),
  },
  { name: 'timeStore' },
)

setInterval(() => {
  timeStore.now = clock()
}, UPDATE_RATE_MS)
