import ReactDOM from 'react-dom'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import './Loader.css'

const Loader = ({ block = false }) => block ? (
  <div className="loader loader_block f ai-c jc-c">
    <CircularProgress color="secondary" size={50} />
  </div>
) : (
  ReactDOM.createPortal(
    <div className="loader f ai-c jc-c">
      <CircularProgress color="secondary" size={50} />
    </div>,
    document.body)
)

export default Loader
