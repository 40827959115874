import React from 'react'
import Button from '@material-ui/core/Button'

import { authStore } from '../../auth/authStore'
import IMAGES from '../../utils/ImagesProvider'
import './Header.css'

const signOut = () => {
  authStore.signOut()
}

const Header = () => (
  <header className="header f jc-sb">
    <div className="header__logo f ai-c">
      <img className="header__logo-image" src={IMAGES.icon_logo} alt="logo" />
      Graymont
    </div>
    <Button onClick={signOut}>Logout</Button>
  </header>
)

export default Header
