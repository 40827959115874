import React from 'react'

import Header from '../Header/Header'
import Tabs from '../Tabs/Tabs'
import Stats from '../Stats/Stats'
import LiveStats from '../LiveStats/LiveStats'
import './MainPage.css'

const MainPage = () => (
  <div className="main-page">
    <Header />
    <main className="main-page__content f ai-fs jc-sb">
      <Tabs />
      <Stats />
      <LiveStats />
    </main>
  </div>
)

export default MainPage
