import './api/aws'
import React from 'react'
import ReactDOM from 'react-dom'

import { scheduleRefresh } from './app/keepUpToDate'
import Root from './app/Root'

scheduleRefresh()

ReactDOM.render(<Root />, document.getElementById('root'))
