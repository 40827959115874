import React from 'react'
import { observer } from 'mobx-react'
import formatDate from 'date-fns/format'

import { visitsStore } from '../../store/visitsStore'
import { timeStore } from '../../store/timeStore'
import IMAGES from '../../utils/ImagesProvider'
import Loader from '../Loader/Loader'
import './LiveHeader.css'

const LiveHeader = () => {
  const { loading, siteCount } = visitsStore.today
  const dateElems = formatDate(timeStore.nowMinuteAccurate, 'h:mm a').split(' ')
  return (
    <div className="live-header">
      <div className="live-header__label label label_small-radius">
        Currently on yard
      </div>
      <div className="live-header__block f ai-fe jc-sb">
        <div className="live-header__time">
          {dateElems[0]}{' '}
          <span className="live-header__time-part">{dateElems[1]}</span>
        </div>
        {loading ? (
          <Loader block />
        ) : (
          <div className="live-header__stats">
            <div className="f ai-c">
              <div className="stats-item__count">{siteCount}</div>
              <img
                width={21}
                height={21}
                src={IMAGES.icon_truck}
                alt="trucks on yard"
              />
            </div>
            <div className="stats-item__legend">trucks on yard</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(LiveHeader)
