/* eslint-disable camelcase */
const icon_logo = require('../assets/images/icon-logo.svg')
const icon_moon = require('../assets/images/icon-moon.svg')
const icon_search = require('../assets/images/icon-search.svg')
const icon_sort_blue = require('../assets/images/icon-sort.svg')
const icon_sort_white = require('../assets/images/icon-sort-white.svg')
const icon_timer = require('../assets/images/icon-timer.svg')
const icon_truck = require('../assets/images/icon-truck.svg')
const icon_truck_checked = require('../assets/images/icon-truck-checked.svg')

export default {
  icon_logo,
  icon_moon,
  icon_search,
  icon_sort_blue,
  icon_sort_white,
  icon_timer,
  icon_truck,
  icon_truck_checked
}
