export const COLORS = {
  white05: 'rgba(255, 255, 255, 0.05)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white70: 'rgba(255, 255, 255, 0.7)',
  black60: 'rgba(0, 0, 0, 0.6)',
  darkGrey: '#212527',
  slateGrey: '#656769',
  warmGrey: '#9b9b9b',
  lightBlue: '#8dcffb',
  sandy: '#f1e76f',
  pear: '#c3ec6c',
  error: '#f47771'
}

export default {
  typography: {
    fontFamily: '"Signika", sans-serif'
  },
  palette: {
    type: 'dark',
    background: {
      paper: COLORS.white05,
      default: COLORS.darkGrey
    },
    primary: {
      main: COLORS.lightBlue
    },
    secondary: {
      main: COLORS.pear
    },
    error: {
      main: COLORS.error
    }
  },
  overrides: {
    MuiButtonBase: {
      root: {
        display: 'block',
        borderRadius: 4
      }
    },
    MuiButton: {
      root: {
        fontSize: 11,
        letterSpacing: 0.5
      },
      sizeSmall: {
        minHeight: 30,
        height: 30,
        padding: '4px 8px',
        fontSize: 11,
        letterSpacing: 0.5
      },
      outlined: {
        border: '1px solid white'
      }
    },
    MuiDialog: {
      paperWidthSm: {
        overflow: 'visible',
        maxWidth: 922
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: COLORS.white10,
        backdropFilter: 'blur(6px)'
      }
    }
  }
}
