export const formatDuration = seconds => {
  let s = seconds
  const d = Math.floor(s / (3600 * 24))
  s -= d * 3600 * 24
  const h = Math.floor(s / 3600)
  s -= h * 3600
  const m = Math.floor(s / 60)
  return `${d ? `${d}d` : ''} ${h}:${m < 10 ? '0' : ''}${m}`
}

export const formatNoDaysDuration = seconds => {
  if (Number.isNaN(seconds)) {
    return ''
  }
  const min = Math.floor((seconds % 3600) / 60)
  return `${Math.floor(seconds / 3600)}:${min < 10 ? '0' : ''}${min}`
}
